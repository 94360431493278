import { NextPageContext } from 'next';

const proxiedFor = 'X-Proxied-For';

export const getProxiedForFromHeaders = (
  headers: NodeJS.Dict<string | string[]>,
) => headers[proxiedFor] || headers[proxiedFor.toLowerCase()] || headers.host;

const absoluteUrlFromInitialProps = (req: NextPageContext['req']) => {
  if (!req?.headers) return '';
  const host = getProxiedForFromHeaders(req.headers);

  const absoluteUrl = `https://${host}${req.url}`;

  return absoluteUrl;
};

export default absoluteUrlFromInitialProps;
