import {
  GET_COLLECTION_FULFILLED,
  GET_COLLECTION_PENDING,
  GET_COLLECTION_REJECTED,
} from '../actions/collections';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  documents: {},
  status: c.DEFAULT,
};

export const filterCollectionItems = ({ metaData }) =>
  metaData && metaData.photo && metaData.photo.id;

const collections = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTION_PENDING:
      if (!action.payload.prefetch) {
        return {
          ...state,
          status: c.LOADING,
        };
      }
      return state;

    case GET_COLLECTION_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case GET_COLLECTION_FULFILLED:
      const { contentId, response } = action.payload;
      let content = response;
      if (Array.isArray(content)) {
        content = content.filter(filterCollectionItems);
      }
      return {
        ...state,
        documents: {
          ...state.documents,
          [contentId]: content,
        },
        status: c.READY,
      };

    default:
      return state;
  }
};

export default collections;
