import events from './events';

const misoUrl = process.env.MISO_API_URL;
const misoKey = process.env.MISO_API_KEY;

function MisoTracking() {
  const trackEvent = (eventName, options) => {
    try {
      if (events[eventName]) {
        const {
          document_id: documentId,
          document_klass: documentKlass,
          document_type: documentType,
          objectId,
          favoritableId,
        } = options;
        const data = {
          type: events[eventName],
          context: {
            page: {
              url: document.location.href,
            },
          },
        };
        if (dry?.user?.currentUser) {
          data.user_id = dry?.user?.currentUser?.id?.toString();
        } else if (mixpanel) {
          const distinctID = mixpanel?.get_distinct_id?.();
          if (!distinctID) return; // skip api call if no distinct ID, will fail without it
          data.anonymous_id = distinctID;
        }
        if (objectId || favoritableId) {
          data.product_ids = [objectId || favoritableId];
        } else if (documentId && (documentKlass || documentType)) {
          // documentId can be the slug - 8125-chocolate-crinkle-cookies
          const id = `${documentId}`.includes('-')
            ? documentId.split('-')[0]
            : documentId;
          data.product_ids = [`${documentKlass || documentType}_${id}`];
        }
        fetch(`${misoUrl}/interactions?api_key=${misoKey}`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: [data] }),
        });
      }
    } catch (err) {
      console.error('Unable to track MISO favorite event', err); // eslint-disable-line no-console
    }
  };

  const trackRouterChange = () => {
    trackEvent('PAGE_VIEW', {
      location: document.location.pathname,
    });
  };

  return {
    trackEvent,
    trackRouterChange,
    trackPageView: trackRouterChange,
  };
}

export default MisoTracking;
