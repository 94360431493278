export const FF_MISE_REVIEWS = process.env.NEXT_PUBLIC_FF_MISE_REVIEWS
  ? process.env.NEXT_PUBLIC_FF_MISE_REVIEWS === 'true'
  : false;

export const FF_RECIPE_TESTER_ARTICLE = process.env
  .NEXT_PUBLIC_FF_RECIPE_TESTER_ARTICLE
  ? process.env.NEXT_PUBLIC_FF_RECIPE_TESTER_ARTICLE === 'true'
  : false;

export const FF_DIGITAL_APP_LANDING_PAGE = process.env
  .NEXT_PUBLIC_FF_DIGITAL_APP_LANDING_PAGE
  ? process.env.NEXT_PUBLIC_FF_DIGITAL_APP_LANDING_PAGE === 'true'
  : false;

export const FF_PREMIUM_SUPPORT_PAGE = process.env
  .NEXT_PUBLIC_FF_PREMIUM_SUPPORT_PAGE
  ? process.env.NEXT_PUBLIC_FF_PREMIUM_SUPPORT_PAGE === 'true'
  : false;

export const FF_THANKSGIVING_HOLIDAY = process.env
  .NEXT_PUBLIC_FF_THANKSGIVING_HOLIDAY
  ? process.env.NEXT_PUBLIC_FF_THANKSGIVING_HOLIDAY === 'true'
  : false;

export const FF_MISE_ARTICLES = process.env.NEXT_PUBLIC_FF_MISE_ARTICLES
  ? process.env.NEXT_PUBLIC_FF_MISE_ARTICLES === 'true'
  : false;

export const FF_RECIPE_TESTER_SUBMIT_V2_ACTIVE = process.env
  .NEXT_PUBLIC_FF_RECIPE_TESTER_SUBMIT_V2_ACTIVE
  ? process.env.NEXT_PUBLIC_FF_RECIPE_TESTER_SUBMIT_V2_ACTIVE === 'true'
  : false;

export const FF_HOMEPAGE_V2_ACTIVE = process.env
  .NEXT_PUBLIC_FF_HOMEPAGE_V2_ACTIVE
  ? process.env.NEXT_PUBLIC_FF_HOMEPAGE_V2_ACTIVE === 'true'
  : false;

export const FF_HOMEPAGE_V2_GTM_TRACKING_ON = process.env
  .NEXT_PUBLIC_FF_HOMEPAGE_V2_GTM_TRACKING_ON
  ? process.env.NEXT_PUBLIC_FF_HOMEPAGE_V2_GTM_TRACKING_ON === 'true'
  : false;
