/* translate ATK event names to Mixpanel lingo */
const events = {
  DOCUMENT_VIEWED: 'Page View',
  PAGEVIEW: 'Page View',
};

// adding an event
// 1) add the key/value pair above
// 2) add matching entry to analytics/track.js
// 3) call analytics.track(YOUR_KEY, yourData) from somewhere

export default events;
