import get from 'lodash.get';
import {
  GET_CONTENT_FULFILLED,
  GET_CONTENT_PENDING,
  GET_CONTENT_REJECTED,
} from '../actions/content';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  documents: {},
  status: c.DEFAULT,
};

function transformItems(items, doc) {
  return items
    .filter((x) => x)
    .map((item) => {
      const type = item.componentType;
      if (type === 'editorMultiContentContainer') {
        item = contentTransforms(item); // eslint-disable-line
      } else if (type === 'cmsComponentDocumentHero') {
        if (doc.label) item.label = doc.label;
        item = {
          id: item.id,
          fields: {
            classNames: ['full-width'],
            items: [item],
            visualStyle: 'contentGroup',
          },
          componentType: 'editorMultiContentContainer',
        };
      }
      return item;
    });
}

/**
 * Custom transformations of component data go here.
 * You might transform something to wrap it with a ContentGroup or
 * add props that might seem mysterious or scary for content editors.
 * @param  {Object} doc Contentful document
 * @return {Object}     Contentful document
 */
function contentTransforms(doc) {
  if (!doc) return doc;

  if (doc.items && doc.items.length > 0) {
    doc.items = transformItems(doc.items, doc);
  }

  if (doc.zypeId && doc.video) {
    if (!doc.metaData) doc.metaData = {};
    doc.metaData.photoOverride = get(doc, 'video.thumbnails[2].url');
  }

  if (doc.relateds && doc.relateds.length > 0) {
    doc.relateds = doc.relateds.filter((x) => x);
  }

  if (
    doc.documentType === 'recipe' &&
    typeof doc.cookingSteps !== 'undefined'
  ) {
    // create mildly sparse index of cooking steps
    // referenced when printing instructions
    doc.stepPhotos = [];
    doc.cookingStepIndex = doc.cookingSteps.reduce((acc, step) => {
      const instructionNumber = get(step, 'fields.instructionNumber');
      if (instructionNumber) {
        const idx = instructionNumber - 1;
        const arr = acc[idx] || [];
        const cloudinaryId = get(step, 'fields.photo.public_id', null);
        const content = get(step, 'fields.content');
        const id = step.id;
        if (cloudinaryId) {
          doc.stepPhotos.push({ cloudinaryId, id });
        }
        arr.push({ content, cloudinaryId, id });
        acc[idx] = arr;
      }
      return acc;
    }, {});
    delete doc.cookingSteps;

    // reference cooking step index and create 'final' carousel list
    // with backfilled content for instructions without steps
    doc.cookingModeData = doc.instructions.reduce(
      (acc, { fields: { content }, id }, idx) => [
        ...acc,
        ...(doc.cookingStepIndex[idx] || [{ content, id }]),
      ],
      [],
    );
  }

  return doc;
}

const content = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_PENDING:
      return {
        ...state,
        status: c.LOADING,
      };

    case GET_CONTENT_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case GET_CONTENT_FULFILLED:
      const { contentId, response } = action.payload;
      return {
        documents: {
          ...state.documents,
          [contentId]: contentTransforms(response),
        },
        status: c.READY,
      };

    default:
      return state;
  }
};

export default content;
