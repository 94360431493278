import Document, {
  DocumentContext,
  DocumentInitialProps,
  Head,
  Html,
  Main,
  NextScript,
} from 'next/document';
import React from 'react';
import { ServerStyleSheet } from 'styled-components';
import {
  initPiano,
  pianoAdblocker,
  pianoConfig,
} from 'components/integrations/PianoSDK/LoadPianoSDK';
import Blueconic from 'components/integrations/Blueconic/Blueconic';
import Facebook from 'components/integrations/Facebook';
import Twitter from 'components/integrations/Twitter/Twitter';
import Dry from '../components/integrations/Dry';
import DryNoJquery from '../components/integrations/DryNoJquery';
import GoogleTagManagerNoScript from '../components/integrations/GoogleTagManagerNoScript';
import { SvgProvider } from '@americastestkitchen/ui/svg/index';
import Script from 'next/script';
import {
  VWOProdSmartcode,
  VWOStaging1Smartcode,
} from '../components/integrations/VisualWebOptimizer/sdkScripts';
import { MonitoringService } from 'services/monitoring.service';

interface ATKInitialProps extends DocumentInitialProps {
  disableTracking: boolean;
  print: false;
  siteKey: 'atk' | 'cio' | 'cco' | 'play';
  browserTimingHeader: string;
}

// Keeping these functions colocated to where they are being used
export const isPlayPagePath = (path: string): boolean => {
  return (
    path.includes('/episode') ||
    path.includes('/podcasts') ||
    path.includes('/shows') ||
    path.includes('/skills') ||
    path.includes('/whats-eating-dan') ||
    path.includes('/gear-heads') ||
    path.includes('/perfectly-seasonal') ||
    path.includes('/proof') ||
    path.includes('/mystery-recipe') ||
    path.includes('/the-walk-in') ||
    path.includes('/videos')
  );
};

// Keeping these functions colocated to where they are being used
export const siteKeyFromPath = (path: string): ATKInitialProps['siteKey'] => {
  if (isPlayPagePath(path)) return 'play';
  if (path.startsWith('/cookscountry')) return 'cco';
  if (path.startsWith('/cooksillustrated')) return 'cio';
  return 'atk';
};

// domainSiteKey will never have play
export const domainSiteKeyFromPath = (
  path: string,
): Exclude<ATKInitialProps['siteKey'], 'play'> => {
  if (path.startsWith('/cookscountry')) return 'cco';
  if (path.startsWith('/cooksillustrated')) return 'cio';
  return 'atk';
};

class AtkDocument extends Document<ATKInitialProps> {
  static async getInitialProps(ctx: DocumentContext): Promise<ATKInitialProps> {
    const monitor = new MonitoringService();
    const browserTimingHeader = await monitor.getMonitorHeader();
    // monitor.log('info', 'TEST WITH SERVICE CLASS', {
    //   application: 'NextJs NewRelic app logging',
    //   test: 'Testing logging with Winston',
    //   pathname: ctx.pathname,
    // });

    const sheet = new ServerStyleSheet();
    const originalRenderPage = ctx.renderPage;

    ctx.renderPage = () =>
      originalRenderPage({
        enhanceApp: (App) => (props) => sheet.collectStyles(<App {...props} />),
      });

    const initialProps = await Document.getInitialProps(ctx);

    const disableTracking = false;
    const print = false;
    const siteKey = siteKeyFromPath(ctx.pathname);

    return {
      ...initialProps,
      browserTimingHeader,
      disableTracking,
      print,
      siteKey,
      styles: [initialProps.styles, sheet.getStyleElement()],
    };
  }

  render() {
    const osanoScript =
      'https://cmp.osano.com/AzqNNFSRwIw6m2xVQ/416922d3-64a5-4e9c-8823-75b4a18c21b4/osano.js';
    const { disableTracking, print, siteKey } = this.props;

    return (
      <Html lang="en">
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: this.props.browserTimingHeader }}
        />
        <Head />
        <body
          className={`${siteKey}${print ? ' print' : ''}`}
          data-disable-tracking={disableTracking}
        >
          <Main />
          <NextScript />
          {/* <Osano strategy="beforeInteractive" /> */}

          <Script src={osanoScript} strategy="beforeInteractive" />

          <Dry siteKey={siteKey} />
          <Script id="piano-adblock" strategy="beforeInteractive">
            {pianoAdblocker}
          </Script>
          <Script
            id="piano-sdk"
            src={
              process.env.ENVIRONMENT_NAME === 'production'
                ? '//cdn.tinypass.com/api/tinypass.min.js'
                : '//sandbox.tinypass.com/api/tinypass.min.js'
            }
            strategy="beforeInteractive"
          />
          <Script id="piano-initialize-settings" strategy="beforeInteractive">
            {pianoConfig}
          </Script>
          <Script id="piano-tp-init-function">{initPiano}</Script>

          {!disableTracking && <DryNoJquery />}
          <link rel="stylesheet" href="https://use.typekit.net/nio2hfe.css" />
          <Blueconic key="blueconic-script" />
          <Facebook key="facebook-script" />
          <Twitter key="twitter-script" />
          {/* <!-- Start VWO Async SmartCode --> */}
          <link
            rel="preconnect"
            href="https://dev.visualwebsiteoptimizer.com"
          />
          <Script id="vwoCode" strategy="beforeInteractive">
            {process.env.ENVIRONMENT_NAME === 'production'
              ? VWOProdSmartcode
              : VWOStaging1Smartcode}
          </Script>
          {/* <!-- End VWO Async SmartCode --> */}
          <SvgProvider />
          {!disableTracking ? (
            <GoogleTagManagerNoScript
              gtmId={process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}
            />
          ) : null}
        </body>
      </Html>
    );
  }
}

export default AtkDocument;
