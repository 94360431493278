import { TrackProvider } from '@americastestkitchen/ui/app/TrackProvider';
import trackUI from './trackUI';
import Footer from '@americastestkitchen/ui/footer/index';
import { FF_DIGITAL_APP_LANDING_PAGE } from 'server/lib/feature-flags';

export default function UIFooter() {
  return (
    <TrackProvider track={trackUI}>
      <Footer linkFarmFeatureFlag={FF_DIGITAL_APP_LANDING_PAGE} />
    </TrackProvider>
  );
}
