const reset = `
  html {
    height: 100%;
    box-sizing: border-box;
    font-size: 62.5%;

    &[data-font-size="small"] {
      font-size: 47%;
    }

    &[data-font-size="large"] {
      font-size: 90%;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    font-weight: inherit;
  }

  *:focus {
    box-shadow: none;
    outline: colors.color(neutral, dark) dotted 2px;
    outline-offset: 2px;
  }

  body {
    min-height: 100%;
    overflow-x: hidden;
    overflow-anchor: none;
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.atk:not(.print) {
      background-color: #F5F5F5;
    }

    &.play:not(.print):not(.error) {
      background-color: #262626;

      main {
        max-width: none;
      }
    }

    &.play.atk:not(.error) {
      background-color: #262626;
    }

    &.load-all-pending {
      cursor: wait !important;
      opacity: 0.5;

      button,
      .button {
        cursor: wait !important;
      }
    }
    .osano-cm-widget { 
      display: none !important;
    }

    html & {
      margin: 0;
    }
  }

  @media (min-width: 1100px) {
    body.cco:not(.print):not(.play):not(.error) {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAAACVBMVEX////49/iop6jQdo0AAAAAEklEQVQI12NgwA8kQAQLAUUMAARaAB3bKYG3AAAAAElFTkSuQmCC') repeat top left;
    }
  }

  body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    ul,
    p,
    figure,
    menu {
      margin: 0;
      padding: 0;
    }
  }

  #global-components-header {
    position: relative;
  }

  main {
    display: block;
    margin: 0 auto;
    max-width: 120rem;
    min-height: 70rem;
  }

  picture {
    display: block;
  }

  a {
    text-decoration: none;
    transition: color 0.2s linear;
  }

  ul,
  ol {
    list-style-type: none;
  }

  img {
    max-width: 100%;
    opacity: 1;
    transition: opacity 0.3s;
  }

  img[data-src] {
    opacity: 0;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
  }

  button {
    background: transparent;
    border: none;
    color: inherit;
    font: inherit;
    padding: 0;
  }

  button:focus,
  *:focus {
    box-shadow: none;
    outline: colors.color(neutral, dark) dotted 2px;
    outline-offset: 2px;
  }

  button,
  [type='button'] {
    -webkit-appearance: none;
  }

  body {
    &.cio {
      font-family: "Merriweather", serif; 
      &.print {
        .osano-cm-window {
          display: none !important;
        }
      }
    }

    &.cco {
      -webkit-tap-highlight-color: rgba(230, 241, 255, 0.5);

      &.print {
        .osano-cm-window {
          display: none !important;
        }
      }
    }

    &.atk {
      &.print {
        .osano-cm-window {
          display: none !important;
        }
      }
    }

    &.modal-open {
      position: fixed;
    }
  }

  .bg-white {
    background-color: white;
  }

  .py-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .py-3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .py-4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  #global-footer {
    position: relative;
    z-index: 1038;
  }

  .atkPinnedHeader {
    z-index: 1042;
  }

  div.atkOffsitePanel {
    z-index: 1041
  }

  .quickview-open {
    overflow: hidden;

    #global-footer {
      z-index: 0;
    }
  }

  @media print {
    .global-nav,
    #atk-global-footer,
    #global-components-header,
    [data-testid="footer"],
    #global-header-wrapper,
    #global-footer {
      display: none;
    }
  }

  #__next {
    position: relative;
  }

  .error-container {
    background-color: white;
  }

  .body-fixed {
    .atkOffsitePanel__blur {
      z-index: 14;
    }
  }

  .carousel-cell[aria-hidden] {
    display: initial;
  }

  .atkGlobalFooter {
    position: relative;
    z-index: 2;
  }
`;

export default reset;
