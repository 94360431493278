import { combineReducers } from 'redux';

import bookRecipes from '../../reducers/bookRecipes';
import collections from '../../reducers/collections';
import content from '../../reducers/content';
import cookingMode from '../../reducers/cookingMode';
import courses from '../../reducers/courses';
import latest from '../../reducers/latest';
import navigationDrawer from '../../reducers/navigationDrawer';
import origin from '../../reducers/origin';
import play from '../../reducers/play';
import quickView from '../../reducers/quickView';
import registration from '../../reducers/registration';
import trending from '../../reducers/trending';

const rootReducer = combineReducers({
  bookRecipes,
  collections,
  content,
  cookingMode,
  courses,
  latest,
  navigationDrawer,
  origin,
  play,
  quickView,
  registration,
  trending,
});

export default rootReducer;
