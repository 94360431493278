const objectIdPrefixes = [
  'recipe',
  'video',
  'article',
  'article_cv',
  'course',
  'how_to',
  'episode',
  'equipment_review',
  'taste_test',
  'magazine',
  'collection',
  'cookbook',
  'buying_guide',
  'book',
  'guide',
] as const;

export type ObjectIdPrefixes = typeof objectIdPrefixes[number];

const mappedValues: Record<string, ObjectIdPrefixes> = {
  cvPageView: 'article_cv',
  'Cms::Page': 'article',
  'Cms::Page::Article': 'article',
  'Cms::Page::Guide': 'guide',
  buyingGuide: 'buying_guide',
  'ReviewSet::EquipmentReview': 'equipment_review',
  Recipe: 'recipe',
  Video: 'video',
};

export function getObjectIdPrefix(
  documentTypeLocations: unknown[],
): ObjectIdPrefixes | undefined {
  const match = objectIdPrefixes.find((validValue) =>
    documentTypeLocations.includes(validValue),
  );
  if (match) return match;

  const mappedMatch = objectIdPrefixes.find((validValue) =>
    documentTypeLocations.some(
      (testValue) =>
        typeof testValue === 'string' && validValue === mappedValues[testValue],
    ),
  );
  if (mappedMatch) return mappedMatch;

  // eslint-disable-next-line no-console
  console.error(`getObjectIdPrefix ${documentTypeLocations} not identifiable`);
  return undefined;
}

export type ObjectId = `${ObjectIdPrefixes}_${number}`;

export default function getObjectId(
  documentTypeLocations: unknown[],
  documentIdLocations: unknown[],
): ObjectId | undefined {
  const matchedId = documentIdLocations.find(
    (testValue) => typeof testValue === 'number',
  ) as number | undefined;
  const prefix = getObjectIdPrefix(documentTypeLocations);

  if (!prefix || !matchedId) return;
  return `${prefix}_${matchedId}`;
}

const favoritableIds: ObjectIdPrefixes[] = [
  'article',
  'buying_guide',
  'collection',
  'course',
  'episode',
  'equipment_review',
  'how_to',
  'recipe',
  'taste_test',
  'video',
];

export function isFavoritableObjectID(objectID: ObjectId) {
  const objectIDPrefix = objectID.match(`([a-z_]+)_`)?.[1];
  return favoritableIds.some((id) => id === objectIDPrefix);
}
