const sandboxPianoConfig = `
  tp = window.tp || [];
  tp.push(["setUsePianoIdUserProvider", false ]);
  tp.push(["setUsePianoIdLiteUserProvider", true ]);
  tp.push(["setUseTinypassAccounts", false]);
  tp.push(["setAid", 'P3MUmmU9pu']);
`;

const prodPianoConfig = `
  tp = window.tp || [];
  tp.push(["setUsePianoIdUserProvider", false ]);
  tp.push(["setUsePianoIdLiteUserProvider", true ]);
  tp.push(["setUseTinypassAccounts", false]);
  tp.push(["setAid", 'o8it4JKTpu']);
  tp.push(["setSandbox", false]);
  tp.push(["setEndpoint", 'https://buy.tinypass.com/api/v3']);
`;

// Setup with DNS Configuration
const sandboxPianoDNSConfig = `
  tp = window.tp || [];
  tp.push(["setUsePianoIdUserProvider", false ]);
  tp.push(["setUsePianoIdLiteUserProvider", true ]);
  tp.push(["setUseTinypassAccounts", false]);
  tp.push(["setAid", 'P3MUmmU9pu']);
  tp.push(['setComposerHost','https://c2-p.americastestkitchen.com']);
  tp.push(['setPianoIdUrl', 'https://auth-p.americastestkitchen.com']);
  tp.push(['setEndpoint', 'https://vx-p.americastestkitchen.com']);
  tp.push(['setStaticDomain', 'https://cdn-p.americastestkitchen.com']);
`;

// Setup with DNS Configuration
const prodPianoDNSConfig = `
  tp = window.tp || [];
  tp.push(["setUsePianoIdUserProvider", false ]);
  tp.push(["setUsePianoIdLiteUserProvider", true ]);
  tp.push(["setUseTinypassAccounts", false]);
  tp.push(["setAid", 'o8it4JKTpu']);
  tp.push(["setSandbox", false]);
  tp.push(['setComposerHost','https://c2-p.americastestkitchen.com']);
  tp.push(['setPianoIdUrl', 'https://auth-p.americastestkitchen.com']);
  tp.push(['setEndpoint', 'https://vx-p.americastestkitchen.com']);
  tp.push(['setStaticDomain', 'https://cdn-p.americastestkitchen.com']);
`;

const stagingConfig = process.env.PIANO_DNS_CONFIG
  ? sandboxPianoDNSConfig
  : sandboxPianoConfig;

const prodConfig = process.env.PIANO_DNS_CONFIG
  ? prodPianoDNSConfig
  : prodPianoConfig;

export const pianoConfig =
  process.env.ENVIRONMENT_NAME === 'production' ? prodConfig : stagingConfig;

export const pianoAdblocker = `
  document.cookie = "__adblocker=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      
  const pCookie = (adblocker) => { 
    var d = new Date(); 
    d.setTime(d.getTime() + 60 * 5 * 1000); 
    document.cookie = "__adblocker=" + (adblocker ? "true" : "false") + "; expires=" + d.toUTCString() + "; path=/"; 
  };
      
  const pscr = document.createElement("script");
  pscr.setAttribute("async", 'true'); 
  pscr.setAttribute("src", "//www.npttech.com/advertising.js");
  pscr.setAttribute("onerror", "typeof pCookie !== 'undefined' && pCookie(true)");
  document.getElementsByTagName("head")[0].appendChild(pscr);
`;

export const initPiano = `
  window.tp.push([
    'init',
    function initExperience() {
      window.tp.experience.init();
    },
  ]);
`;
